const playerData = {
	"simen kloster-jensen": {
		strength: "God på å skape splid rundt bordet",
		weakness: "Havner ofte på tilt",
	},
	"luka vucenovic": {
		strength: "Lager alltid god S",
		weakness: "Ikke spesielt god i Catan",
	},
	"kim anh doan": {
		strength: "Sweettalker",
		weakness: "Nekter å vinne med mindre han også kjøper 10+ devs",
	},
	"martin cubas": {
		strength: "God strategist",
		weakness: "Hater å bli plowa",
	},
	"markus heyerdahl roland": {
		strength: "God på å gå under radaren",
		weakness: "Loosey goosey trader",
	},
};

export default playerData;
