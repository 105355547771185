import "./Header.css";

const Header = () => {
	return (
		<h1>
			<a href="/" className="effect-shine">
				Settlers of Eidsvoll
			</a>
		</h1>
	);
};

export default Header;
